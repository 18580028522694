
<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame">
          <div class="innerframe">
            <div v-html="gameData.body"></div>

            <template v-if="error == true">
              <div style="font-size: 20px;" v-html="gameData.incorrect"></div>
            </template>

            <div class="containerNoBack">
              <table id="stage17tbl" style=" text-align: left;">
              </table>
            </div>

            <!-- <template v-for="(question, index) in this.choices">
              <div class="question ">
                <div class="center">
                  <tr class="center">
                    <input type="checkbox" :id="{ question }" :value="{ question }" v-model="choices" /> {{ question }} {{
                      index }}
                  </tr>
                </div>
              </div>
            </template> -->

            <div class="field field--button btnwidth">
              <button class="button" @click="check()" :style="{ 'background-color': $parent.buttonColor }"
                type="submit">{{
                  gameData.button }}</button>
            </div>

          </div>

        </div>

      </div>
    </div>
  </div>
</template>


<style>
@import '../assets/form.css';
</style>

<style scoped></style>

<script>
import axios from 'axios'

export default {
  name: 'QuestionsMultiChoice',
  props: {
    msg: String,
    dbid: String,
    gameName: String,
    storeOnly: String,
    overideData: Object,
    dataOveride: String
  },
  async mounted() {
    this.gameData = this.$parent.language[this.gameName]

    this.choices = []
    if (this.dataOveride == "true") {
      var chosenItems = this.overideData
      for (var item of chosenItems) {
        this.choices[item] = this.gameData.choices[item]
      }

    } else {
      this.choices = this.gameData.choices
    }

    this.answers = this.gameData.answers
    this.numberOfChoices = this.gameData.choices.length
    this.populateTable()


    axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": this.gameName + "Start", "dbid": this.dbid })
    document.querySelector("#app > div.outer > div > div > div").style.borderColor = this.$parent.gameSettings.containerColor
    this.$emit('pauseTimer', 0)
  },
  data() {
    return {
      gameData: [],
      choices: [],
      answers: [],
      chosen: [],
      numberOfChoices: 0,
      error: false
    }
  },
  methods: {
    shuffleArray(array) {
      for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
      }
    },
    populateTable() {
      // document.querySelectorAll("table tbody tr").forEach(function (e) { e.remove() })
      document.getElementById("stage17tbl").innerHTML = "";

      var table = document.getElementById('stage17tbl');

      var array = Object.keys(this.choices)
      for (var i = 0; i < array.length; i += 2) {
        var row = table.insertRow(0);
        var cell1 = row.insertCell(0);
        var cell2 = row.insertCell(1);
        cell1.innerHTML = ' <label class="containerCheck">' + this.choices[array[i]] + '<input type="checkbox" id="' + array[i] + '" > <span class="checkmark"></span></label>'

        if (Object.keys(this.choices).length > 1) {
          cell2.innerHTML = ' <label class="containerCheck">' + this.choices[array[i + 1]] + '<input type="checkbox" id="' + array[i + 1] + '" > <span class="checkmark"></span></label>'
        }
        // cell1.innerHTML = '<input type="checkbox" class="choiceBox" id="' + array[i] + '" value=""  />' + this.choices[array[i]];
        //   cell2.innerHTML = '<input type="checkbox" class="choiceBox" id="' + array[i + 1] + '" value=""  />' + this.choices[array[i + 1]];
      }
      // document.getElementsByTagName('body')[0].appendChild(table);
    },
    chosenbox(number) {
      alert(number)
    },
    check() {
      this.error = false
      this.chosen.length = 0
      var cbs = document.getElementsByTagName('input');
      for (var i = 0; i < cbs.length; i++) {
        if (cbs[i].type == 'checkbox') {
          if (cbs[i].checked === true) {

            this.chosen.push(cbs[i].id)
          }
        }
      }



      if (this.storeOnly == "true") {

        console.log("===")
        console.log(this.answers.every(ai => this.chosen.includes(ai)))
        if (this.answers.every(ai => this.chosen.includes(ai))) {

          this.$emit('storeAnswers', { answers: this.chosen, game: "stage26" })
          this.$emit('updatestage', "")
        } else {
          this.error = true
        }
      } else {

        if (this.chosen.length != this.answers.length) {
          this.error = true

        } else {

          if (this.chosen.every(r => this.answers.includes(r))) {
            axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": this.gameName + "End", "dbid": this.dbid })
            this.$emit('updatestage', "")
          } else {
            this.error = true
          }

        }
      }

    },


  }
}
</script>


