
<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame">
          <div class="innerframe">
            <div v-html="gameData.body" class="headdingPadding"></div>
            <div class="questionRow">
              <div class="Singlequestion">
                <div class="row btnwidthSingleQuestion rowlabel">
                  <div v-html="gameData.question"></div>
                </div>
                <div class="row btnwidthSingleQuestion rowlabel socialquestion">

                  <div class="field">
                    <input class="input" placeholder="DD/MM/YYYY" v-model="question"
                      v-bind:class="{ greenAnswer: question1ok, redAnswer: question1wrong }" />
                  </div>
                  <div class="field field--button ">
                    <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
                      @click="checkQuestion(1)">{{ gameData.check }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';
</style>

<script>
import { callApi } from '../plugins/callApi.js'

export default {
  name: 'SingleQuestion',
  props: {
    msg: String,
    dbid: String,
    gameName: String
  },
  async mounted() {
    this.$emit('startTimer', 1)
    console.log(this.gameName)
    console.log(this.dbid)
    this.gameData = this.$parent.language[this.gameName]
    // axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": this.gameName + "Start", "dbid": this.dbid })
    var setup = await callApi("updateprogress", this.license, { "gameName": this.gameName + "Question1", "dbid": this.dbid })
    document.querySelector("#app > div.outer > div > div > div").style.borderColor = this.$parent.gameSettings.containerColor
    this.$emit('pauseTimer', 0)
  },
  data() {
    return {
      gameData: [],
      question: "",
      question1ok: "",
      question1wrong: "",
    }
  },
  methods: {
    async checkQuestion(number) {
      if (number == 1) {
        this.question1ok = ""
        this.question1wrong = ""
        if (this.question.toLowerCase() == this.gameData.answer) {
          this.question1ok = true
        } else {
          this.question1wrong = true
          this.$emit('Penalty', "")
          //   axios.post(process.env.VUE_APP_ip + '/incorrectattempt', { "gameName": this.gameName + "Question1", "dbid": this.dbid })
          var setup = await callApi("incorrectattempt", this.license, { "gameName": this.gameName + "Question1", "dbid": this.dbid })
        }
      }



      if (this.question1ok == true) {
        // axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": this.gameName + "End", "dbid": this.dbid })
        var setup = await callApi("updateprogress", this.license, { "gameName": this.gameName + "Question1", "dbid": this.dbid })
        this.$emit('updatestage', "")
      } else {
        // axios.post(process.env.VUE_APP_ip + '/incorrectattempt', { "gameName": this.gameName, "dbid": this.dbid })
        var setup = await callApi("incorrectattempt", this.license, { "gameName": this.gameName + "Question1", "dbid": this.dbid })
      }

    },
  }
}
</script>


