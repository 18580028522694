<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame">
          <div class="innerframe">
            <div v-html="$parent.language[this.gameName].body"></div>


            <table class="messageTable">
              <tr>
                <th>{{ $parent.language[this.gameName].messageNo }}</th>
                <th>{{ $parent.language[this.gameName].YourChoice }}</th>
                <th>{{ $parent.language[this.gameName].CorrectChoice }}</th>
              </tr>
              <tr>
                <td>{{ $parent.language[this.gameName].Message1 }}</td>
                <template v-if="this.info.message1answer != null">

                  <template v-if="this.info.message1answer == 0">
                    <td>{{ $parent.language[this.gameName].safe }}</td>
                  </template>
                  <template v-else>
                    <td>{{ $parent.language[this.gameName].notsafe }}</td>
                  </template>
                  <td>{{ $parent.language[this.gameName].notsafe }}</td>

                </template>
                <template v-else>
                  <td>?</td>
                  <td>?</td>
                </template>
              </tr>
              <tr>
                <td>{{ $parent.language[this.gameName].Message2 }}</td>
                <template v-if="this.info.message2answer != null">

                  <template v-if="this.info.message2answer == 0">
                    <td>{{ $parent.language[this.gameName].safe }}</td>
                  </template>
                  <template v-else>
                    <td>{{ $parent.language[this.gameName].notsafe }}</td>
                  </template>
                  <td>{{ $parent.language[this.gameName].notsafe }}</td>

                </template>
                <template v-else>
                  <td>?</td>
                  <td>?</td>
                </template>
              </tr>

              <tr>
                <td>{{ $parent.language[this.gameName].Message3 }}</td>
                <template v-if="this.info.message3answer != null">

                  <template v-if="this.info.message3answer == 0">
                    <td>{{ $parent.language[this.gameName].safe }}</td>
                  </template>
                  <template v-else>
                    <td>{{ $parent.language[this.gameName].notsafe }}</td>
                  </template>
                  <td>{{ $parent.language[this.gameName].safe }}</td>

                </template>
                <template v-else>
                  <td>?</td>
                  <td>?</td>
                </template>
              </tr>

            </table>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';
</style>

<script>
import { callApi } from '../plugins/callApi.js'

export default {
  name: 'StartGame',
  props: {

    dbid: String,
    gameName: String,
  },
  data() {
    return {
      teamName: "",
      done: false,
      info: {
        message1answer: null,
        message2answer: null,
        message3answer: null
      }
    }
  },
  async mounted() {

    document.querySelector("#app > div.outer > div > div > div").style.borderColor = this.$parent.gameSettings.containerColor

    this.$emit('pauseTimer', 0)

    // axios.post(process.env.VUE_APP_ip + '/messagestart', { "dbid": this.dbid }) //start the message game

    var setup = await callApi("updateprogress", this.license, { "gameName": "startMessages", "dbid": this.dbid })
    let intervalId = window.setInterval(async () => {
      var gameData = await callApi("messagesgame", this.license, { "gameName": process.env.VUE_APP_gameName, "dbid": this.dbid, "type": "check" })
      console.log("====!!!")
      console.log(gameData)
      console.log("====")
      this.info = gameData.body


      if (this.info.message1answer != null && this.info.message2answer != null && this.info.message3answer != null) {

        if (this.done == false) {
          window.clearInterval(intervalId);
          this.$emit('updatestage', "")
          this.done = true
        }

      }
    }, 1000);
  },
  methods: {

  }
}
</script>


