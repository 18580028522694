<template :style="dynamicStyle">
  <template v-if="stage == 'agentCode'">
    <setup @dbidfu="dbidupdate" @langup="setLanguage" @vidoePathSetup="VideoPath" @langdata="GlobalLanguageContent"
      @gamedata="gameSetting" @gameStages="setStages" @updatestage="updateStage" @videoBackStage="setupBackButton" />
  </template>

  <template v-if="this.stageType == 'StartGame'">
    <StartGame @dbidfu="dbidupdate" @langup="setLanguage" @langdata="GlobalLanguageContent" @gamedata="gameSetting"
      @updatestage="updateStage" :dbid="this.dbid" :gameName="this.stage" />
  </template>

  <template v-if="this.stageType == 'StartGameBoxId'">
    <StartGameBoxId @dbidfu="dbidupdate" @langup="setLanguage" @langdata="GlobalLanguageContent" @gamedata="gameSetting"
      @updatestage="updateStage" :dbid="this.dbid" :gameName="this.stage" />
  </template>

  <template v-if="this.stageType == 'StartGameTeam'">
    <StartGameTeam @dbidfu="dbidupdate" @langup="setLanguage" @langdata="GlobalLanguageContent" @gamedata="gameSetting"
      @updatestage="updateStage" :dbid="this.dbid" :gameName="this.stage" />
  </template>

  <template v-if="stage == 'languageChoice'">
    <language @dbidfu="dbidupdate" @langChoise="LanguageChoice" @updatestage="updateStage" />
  </template>


  <template v-if="this.stageType == 'TextViewdy'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.GlobalLanguageContentObj[this.chosenLanguage][this.stage].body"
      :button="this.GlobalLanguageContentObj[this.chosenLanguage][this.stage].button" @pauseTimer="manageTimer" />
  </template>

  <template v-if="this.stageType == 'videody'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      :video="this.stage" :language="this.chosenLanguage" :videoPath="this.videoPath" @pauseTimer="manageTimer" />
  </template>

  <template v-if="this.stageType == 'encryption'">
    <encryption @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :gameName="this.stage" @pauseTimer="manageTimer" />
  </template>

  <template v-if="stageType == 'socialQuestions'">
    <socialQuestions :dbid="this.dbid" @pauseTimer="manageTimer" @startTimer="enableTimer" @updatestage="updateStage"
      :gameName="this.stage" />
  </template>

  <template v-if="stageType == 'messagesGame'">
    <messagesGame :dbid="this.dbid" :info="this.info" @pauseTimer="manageTimer" @addPenalty="addPenalty"
      :gameName="this.stage" @updatestage="updateStage" />
  </template>

  <template v-if="stageType == 'bruteClueManual'">
    <bruteClueManual :dbid="this.dbid" :info="this.info" @pauseTimer="manageTimer" @addPenalty="addPenalty"
      :gameName="this.stage" @updatestage="updateStage" />
  </template>

  <template v-if="stageType == 'SingleQuestionDate'">
    <SingleQuestionDate :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" :gameName="this.stage"
      @startTimer="enableTimer" />
  </template>

  <template v-if="stageType == 'QuestionsMultiChoiceMultiCol'">
    <QuestionsMultiChoiceMultiCol @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage"
      @startTimer="enableTimer" :gameName="this.stage" />
  </template>

  <template v-if="stageType == 'QuestionsMultiChoice'">
    <QuestionsMultiChoice @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :gameName="this.stage" />
  </template>


  <template v-if="stageType == 'MultiQuestion'">
    <MultiQuestion :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" :gameName="this.stage"
      @Penalty="addPenalty" />
  </template>

  <!-- ====================== -->

  <template v-if="stage == 'demo2PlayScreen'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.demo2PlayScreen.body" :button="this.language.demo2PlayScreen.button" />
  </template>



  <template v-if="stage == 'endScreen'">
    <endScreen @reset="endGameReset" @updatestage="updateStage" :dbid="this.dbid" />
  </template>

  <template v-if="stage == 'demo1StartVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="demo1StartVideo" />
  </template>

  <template v-if="stage == 'demo1PlayScreen'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.demo1PlayScreen.body" :button="this.language.demo1PlayScreen.button" />
  </template>

  <template v-if="stage == 'demo1Code'">
    <singleQuestion :dbid="this.dbid" @pauseTimer="manageTimer" @updatestage="updateStage" gameName="demo1Code"
      @startTimer="enableTimer" />
  </template>


  <template v-if="stage == 'demo1EndVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="demo1EndVideo" @pauseTimer="manageTimer" />
  </template>

  <template v-if="stage == 'demo2StartVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="demo2StartVideo" />
  </template>

  <template v-if="stage == 'demo2PlayScreen'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.demo2PlayScreen.body" :button="this.language.demo2PlayScreen.button" />
  </template>

  <template v-if="stage == 'demo2Code'">
    <encryption @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      gameName="demo2Code" />
  </template>

  <template v-if="stage == 'demo2EndVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="demo2EndVideo" />
  </template>


  <template v-if="stage == 'demo3StartVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="demo3StartVideo" />
  </template>

  <template v-if="stage == 'demo3PlayScreen'">
    <TextViewdy @reset="endGameReset" :dbid="this.dbid" @updatestage="updateStage" @startTimer="enableTimer"
      :text="this.language.demo3PlayScreen.body" :button="this.language.demo3PlayScreen.button" />
  </template>


  <template v-if="stage == 'demo3Code'">
    <socialQuestions :dbid="this.dbid" @pauseTimer="manageTimer" @startTimer="enableTimer" @updatestage="updateStage" />
  </template>

  <template v-if="stage == 'demo3EndVideo'">
    <videody @reset="endGameReset" :dbid="this.dbid" :videoData="this.videoData" @updatestage="updateStage"
      video="demo3EndVideo" />
  </template>

  <!--  -->
  <template v-if="timer == 1 && this.gameSettings.clock != 'clock'">
    <Timer :pause="this.pauseTimer" :penalty="this.penalty" @updateTimer="updateTimer" @penaltyDone="penaltyDone" />

  </template>

  <template v-if="timer == 1 && this.gameSettings.clock == 'clock'">
    <timerClock :pause="this.pauseTimer" :penalty="this.penalty" @updateTimer="updateTimer" @penaltyDone="penaltyDone" />
  </template>

  <template v-if="stage != 'agentCode'">
    <cornerLogo @updatestage="updateStage" @previousstage="previousStage" />
  </template>

  <template v-if="stage != 'agentCode' && stage != 'mobileNumber'">
    <background />
  </template>
</template>

<script>





import axios from 'axios'
import cornerLogo from './components/cornerLogo.vue'
import setup from './components/setup.vue'
import StartGame from './components/StartGame.vue'
import TextView from './components/TextView.vue'
import TextView2 from './components/TextView2.vue'
import Timer from './components/timer.vue'
import hackerVideo from './components/hackerVideo.vue'
import bruteVideo from './components/bruteVideo.vue'
import bruteClue from './components/bruteClue.vue'
import bruteClueManual from './components/bruteClueManual.vue'
import encryption from './components/encryption.vue'
import socialVideo from './components/socialVideo.vue'
import socialQuestions from './components/socialQuestions.vue'
import encryptionVideo from './components/encryptionVideo.vue'
import messagesVideo from './components/messagesVideo.vue'
import messagesGame from './components/messagesGame.vue'
import finalHacker from './components/finalHacker.vue'
import endGame from './components/endGame.vue'
import endScreen from './components/endScreen.vue'
import trainingVideo from './components/trainingVideo.vue'
import language from './components/language.vue'
const videos = require('./plugins/video.js')
const logo = require('./plugins/logo.js')
import passwordVideo from './components/passwordVideo.vue'
import password from './components/password.vue'
import explainerVideo from './components/explainerVideo.vue'
import TextViewdy from './components/TextViewdy.vue'
import Questions from './components/Questions.vue'
import MultiQuestion from './components/QuestionsMultiChoice.vue'
import timerClock from './components/timerClock.vue'
import SingleQuestion from './components/SingleQuestion.vue'
import SingleQuestionLong from './components/SingleQuestionLong.vue'
import videody from './components/videody.vue'
import midClue from './components/midClue.vue'
import QuestionsTickChoice from './components/QuestionsTickChoice.vue'
import mobileNumber from './components/mobileNumber.vue'
import background from './components/background.vue'
import StartGameDemo from './components/StartGameDemo.vue'
import StartGameBoxId from './components/StartGameBoxId.vue'
import SingleQuestionDate from './components/SingleQuestionDate.vue'
import QuestionsMultiChoiceMultiCol from './components/QuestionsMultiChoiceMultiCol.vue'
import StartGameTeam from './components/StartGameTeam.vue'

export default {
  name: 'App',
  components: {
    passwordVideo,
    password,
    cornerLogo,
    StartGame,
    StartGameDemo,
    TextView,
    TextView2,
    hackerVideo,
    bruteVideo,
    Timer,
    encryption,
    socialVideo,
    socialQuestions,
    bruteClue,
    bruteClueManual,
    encryptionVideo,
    messagesVideo,
    messagesGame,
    finalHacker,
    endGame,
    endScreen,
    setup,
    language,
    trainingVideo,
    explainerVideo,
    TextViewdy,
    Questions,
    MultiQuestion,
    timerClock,
    SingleQuestion,
    videody,
    midClue,
    SingleQuestionLong,
    QuestionsTickChoice,
    mobileNumber,
    background,
    StartGameBoxId,
    SingleQuestionDate,
    QuestionsMultiChoiceMultiCol,
    StartGameTeam
  },
  created() {
    document.title = "Xcapism Learning";
  },
  watch: {
    stage: function (value) {
      console.log("stage changed to " + value)
      console.log(value) // log it BEFORE model is changed

      if (this.stage == this.gameSettings.timerStartStage) {
        this.timer = 1;
      }

      if (value != "languageChoice") {
        this.stageType = "off"
        console.log(this.GlobalLanguageContentObj[this.chosenLanguage])
        console.log(this.GlobalLanguageContentObj[this.chosenLanguage][value].type)
        switch (this.GlobalLanguageContentObj[this.chosenLanguage][value].type) {
          case "StartGame":
            this.stageType = "StartGame"
            break;
          case "StartGameTeam":
            this.stageType = "StartGameTeam"
            break;
          case "StartGameBoxId":
            this.stageType = "StartGameBoxId"
            break;
          case "TextViewdy":
            this.stageType = "TextViewdy"
            break;
          case "videody":
            this.stageType = "videody"
            break;
          case "encryption":
            this.stageType = "encryption"
            break;
          case "socialQuestions":
            this.stageType = "socialQuestions"
            break;
          case "messagesGame":
            this.stageType = "messagesGame"
            break;
          case "bruteClueManual":
            this.stageType = "bruteClueManual"
            break;
          case "SingleQuestionDate":
            this.stageType = "SingleQuestionDate"
            break;
          case "QuestionsMultiChoiceMultiCol":
            this.stageType = "QuestionsMultiChoiceMultiCol"
            break;
          case "MultiQuestion":
            this.stageType = "MultiQuestion"
            break;
          case "QuestionsMultiChoice":
            this.stageType = "QuestionsMultiChoice"
            break;
          default:
            break;
        }
      }
    }
  },
  data() {
    return {
      info: '',
      name: '',
      startsound: false,
      stage: "agentCode",
      timer: 0,
      pauseTimer: 0,
      timerValue: 0,
      penalty: false,
      penaltyCount: 0,
      dbid: "62816a01c0014f5adad845d5",
      language: '',
      langOptions: '',
      chosenLanguage: '',
      videoData: [],
      logoData: [],
      videoPath: 'XL',
      GlobalLanguageContentObj: {},
      gameSettings: {},
      gameBackground: "#172031",
      buttonColor: "#f29412",
      logoFile: "logo1.png",
      gameStages: [],
      mobileNumber: "",
      background: 1,
      stageType: "",
      backButton: []

    }
  },
  async mounted() {


    this.videoData = await videos.setup()
    this.logoData = await logo.setup()


    switch (this.GlobalLanguageContentObj[this.language][this.stage].type) {
      case TextViewdy:
        console.log("TextViewdy")
        break;

      default:
        break;
    }



  },
  methods: {
    endGameReset() {
      console.log("reset")
      this.info = ''
      this.name = ''
      this.startsound = false
      this.stage = "agentCode"
      this.timer = 0
      this.pauseTimer = 0
      this.timerValue = 0
      this.penalty = false
      this.penaltyCount = 0
      this.dbid = "62816a01c0014f5adad845d5"
    },
    penaltyDone() {
      this.penalty = false
      this.penaltyCount += 1
    },
    addPenalty() {
      this.penalty = true
    },
    enableTimer(value) {
      this.timer = value

    },
    setupBackButton(value) {
      this.backButton = value
    },
    updateTimer(value) {
      this.timerValue = value

    },
    getNotifications() {
      return axios
        .post(process.env.VUE_APP_ip + '/gamedata', { "dbid": this.dbid })
    },
    manageTimer(value) {
      this.pauseTimer = value;
    },
    dbidupdate(value) {
      this.dbid = value
    },
    setLanguage(value) {
      this.langOptions = value
    },
    setStages(value) {
      this.gameStages = value
    },
    SavemobileNumber(value) {
      console.log(value)
      this.mobileNumber = value
      this.updateStage()
    },
    updateStage(value) {
      var newIndex = (this.gameStages.indexOf((this.stage))) + 1
      this.stage = this.gameStages[newIndex]
    },
    previousStage(value) {
      var newIndex = (this.gameStages.indexOf((this.stage))) - 1
      this.stage = this.gameStages[newIndex]
    },
    LanguageChoice(value) {
      this.chosenLanguage = value
      this.language = this.GlobalLanguageContentObj[value]
    },
    VideoPath(value) {
      this.videoPath = value
    },
    GlobalLanguageContent(value) {
      this.GlobalLanguageContentObj = value
    },
    gameSetting(value) {
      this.gameSettings = value

      // here we can set any global chnages
      this.gameSettings.background = value.background
      document.body.style.backgroundColor = this.gameSettings.background;
      document.body.style.color = this.gameSettings.textColor;
      // document.querySelector("#app > div.outer > div > div > div").style.borderColor = this.gameSettings.containerColor
      this.buttonColor = value.buttonColor
      this.logoFile = this.gameSettings.logoFile
      this.containerColor = this.gameSettings.containerColor // "#f29412" 


    },

  }
}
</script>

<style>
@import './assets//styles.css';
</style>



