<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame videobackground">

          <video ref="videoPlayer" id="vidId" autoplay class="videoPlayer" @ended="onEnd">
            <source :src="this.videoData[this.videoPath][this.language][this.video]" type="video/mp4" />
            <track :src="this.videoData[this.videoPath][this.language][this.video + 'Subs']" kind="subtitles" srclang="en"
              label="English" default>
          </video>


        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';
</style>

<script>
import axios from 'axios'

export default {
  name: 'videoPlayer',
  props: {
    msg: String,
    dbid: String,
    videoData: Object,
    video: String,
    language: String,
    videoPath: String
  },
  async mounted() {
    this.$emit('pauseTimer', 1)
    console.log("mounted")
    console.log(this.videoData)
    console.log(this.videoPath)
    console.log(this.language)
    console.log(this.video + '.mp4')
    //  setTimeout(() => { this.GameMove() }, this.videoData[this.$parent.videoPath][this.$parent.chosenLanguage][this.video + 'Time']);
  },
  data() {
    return {
      teamName: ""
    }
  },
  methods: {
    GameMove() {
      var video = document.getElementById('vidId');
      video.currentTime = 0;
      video.load();
      this.$emit('updatestage', "")
    },
    onEnd() {
      console.log("end")
      setTimeout(() => { this.GameMove() }, 2);

    }
  }
}
</script>


