
<template>
  <div class="outer">
    <div class="middle">
      <div class="center">

        <div class="frame">
          <div class="innerframe">
            <div style="font-size: 20px;" v-html="gameData.body"></div>
            <div class="question ">
              <div class="row  rowlabel">
                <div style="font-size: 20px;" v-html="gameData.question1"></div>
              </div>
              <div class="row rowlabel ">

                <div class="field">
                  <!-- <input class="input" placeholder="" v-model="question1"  /> -->
                  <select style="font-size: 20px;" class="select "
                    v-bind:class="{ greenAnswer: question1ok, redAnswer: question1wrong }" v-model="question1">
                    <option>Select</option>
                    <option v-for="answer in gameData.answers1">{{ answer }}</option>
                  </select>
                </div>
                <div class="field field--button ">
                  <button class="button " type="submit" :style="{ 'background-color': $parent.buttonColor }"
                    @click="checkQuestion(1)">{{ gameData.check }}</button>
                </div>
              </div>
            </div>
            <template v-if="gameData.question2 != ''">
              <div class="question multichoice">
                <div class="row rowlabel">
                  <div style="font-size: 20px;" v-html="gameData.question2"></div>
                </div>
                <div class="row  rowlabel multrow ">

                  <div class="field">
                    <select style="font-size: 20px;" class="select"
                      v-bind:class="{ greenAnswer: question2ok, redAnswer: question2wrong }" v-model="question2">
                      <option>Select</option>
                      <option v-for="answer in gameData.answers2">{{ answer }}</option>
                    </select>
                  </div>
                  <div class="field field--button">
                    <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
                      @click="checkQuestion(2)">{{ gameData.check }}</button>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="gameData.question3 != ''">
              <div class="question multichoice">
                <div class="row  rowlabel">
                  <div style="font-size: 20px;" v-html="gameData.question3"></div>
                </div>
                <div class="row  rowlabel">

                  <div class="field">
                    <select style="font-size: 20px;" class="select"
                      v-bind:class="{ greenAnswer: question3ok, redAnswer: question3wrong }" v-model="question3">
                      <option>Select</option>
                      <option v-for="answer in gameData.answers3">{{ answer }}</option>
                    </select>
                  </div>
                  <div class="field field--button">
                    <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
                      @click="checkQuestion(3)">{{ gameData.check }}</button>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="gameData.question4 != ''">
              <div class="question">
                <div class="row  rowlabel">
                  <div v-html="gameData.question4"></div>
                </div>
                <div class="row  rowlabel">

                  <div class="field">
                    <select style="font-size: 20px;" class="select"
                      v-bind:class="{ greenAnswer: question4ok, redAnswer: question4wrong }" v-model="question4">
                      <option>Select</option>
                      <option v-for="answer in gameData.answers4">{{ answer }}</option>
                    </select>
                  </div>
                  <div class="field field--button">
                    <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
                      @click="checkQuestion(4)">{{ gameData.check }}</button>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="gameData.question5 != ''">
              <div class="question">
                <div class="row btnwidth rowlabel">
                  <div v-html="gameData.question5"></div>
                </div>
                <div class="row btnwidth rowlabel">

                  <div class="field">
                    <select class="select" v-bind:class="{ greenAnswer: question5ok, redAnswer: question5wrong }"
                      v-model="question5">
                      <option>Select</option>
                      <option v-for="answer in gameData.answers5">{{ answer }}</option>
                    </select>
                  </div>
                  <div class="field field--button">
                    <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
                      @click="checkQuestion(5)">{{ gameData.check }}</button>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';
</style>

<script>
import axios from 'axios'

function shuffleArray(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
}

export default {
  name: 'QuestionsMultiChoice',
  props: {
    msg: String,
    dbid: String,
    gameName: String
  },
  async mounted() {
    this.gameData = this.$parent.language[this.gameName]
    shuffleArray(this.gameData.answers1)
    shuffleArray(this.gameData.answers2)
    shuffleArray(this.gameData.answers3)
    shuffleArray(this.gameData.answers4)
    shuffleArray(this.gameData.answers5)
    //this.gameData.answers1 = shuffleArray(this.gameData.answers1)



    axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": this.gameName + "Start", "dbid": this.dbid })
    document.querySelector("#app > div.outer > div > div > div").style.borderColor = this.$parent.gameSettings.containerColor
    this.$emit('pauseTimer', 0)
  },
  data() {
    return {
      gameData: [],
      question1: "Select",
      question2: "Select",
      question3: "Select",
      question4: "Select",
      question5: "Select",
      question1ok: "",
      question1wrong: "",
      question2ok: "",
      question2wrong: "",
      question3ok: "",
      question3wrong: "",
      question4ok: "",
      question4wrong: "",
      question5ok: "",
      question5wrong: ""
    }
  },
  methods: {
    checkQuestion(number) {
      if (number == 1) {
        this.question1ok = ""
        this.question1wrong = ""
        if (this.question1 == this.gameData.answer1) {
          this.question1ok = true
        } else {
          this.question1wrong = true
          axios.post(process.env.VUE_APP_ip + '/incorrectattempt', { "gameName": this.gameName + "Question1", "dbid": this.dbid })
        }
      }

      if (number == 2) {
        this.question2ok = ""
        this.question2wrong = ""
        if (this.question2 == this.gameData.answer2) {
          this.question2ok = true
        } else {
          this.question2wrong = true
          this.$emit('Penalty', "")
          axios.post(process.env.VUE_APP_ip + '/incorrectattempt', { "gameName": this.gameName + "Question2", "dbid": this.dbid })
        }
      }

      if (number == 3) {
        this.question3ok = ""
        this.question3wrong = ""
        if (this.question3 == this.gameData.answer3) {
          this.question3ok = true
        } else {
          this.question3wrong = true
          this.$emit('Penalty', "")
          axios.post(process.env.VUE_APP_ip + '/incorrectattempt', { "gameName": this.gameName + "Question3", "dbid": this.dbid })
        }
      }
      if (number == 4) {
        this.question4ok = ""
        this.question4wrong = ""
        console.log(this.question4)
        if (this.question4 == this.gameData.answer4) {
          this.question4ok = true
        } else {
          this.question4wrong = true
          this.$emit('Penalty', "")
          axios.post(process.env.VUE_APP_ip + '/incorrectattempt', { "gameName": this.gameName + "Question4", "dbid": this.dbid })
        }
      }
      if (number == 5) {
        this.question5ok = ""
        this.question5wrong = ""
        console.log(this.question5)
        if (this.question5 == this.gameData.answer5) {
          this.question5ok = true
        } else {
          this.question5wrong = true
          this.$emit('Penalty', "")
          axios.post(process.env.VUE_APP_ip + '/incorrectattempt', { "gameName": this.gameName + "Question5", "dbid": this.dbid })
        }
      }

      if (this.gameData.question2 == '') {
        this.question2ok = true
      }

      if (this.gameData.question3 == '') {
        this.question3ok = true
      }
      if (this.gameData.question4 == '') {
        this.question4ok = true
      }

      if (this.gameData.question5 == '') {
        this.question5ok = true
      }


      if (this.question1ok == true && this.question2ok == true && this.question3ok == true && this.question4ok == true && this.question5ok == true) {
        axios.post(process.env.VUE_APP_ip + '/updateprogress', { "gameName": this.gameName + "End", "dbid": this.dbid })
        this.$emit('updatestage', "")
      }

    },
  }
}
</script>


