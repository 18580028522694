<template>
  <img alt="Codfather Logo" :src="$parent.logoData[$parent.logoFile]" height="350" style="margin-top: 40px;">
  <div class="center">
    <p> {{ $parent.language[this.gameName].headding }} </p>
    <p v-bind:class="{ isred: nameError }"> {{ $parent.language[this.gameName].body }} </p>

    <div class="container" v-bind:class="{ green: clicked }">
      <div class="row">
        <div class="field">
          <input class="input" :placeholder='$parent.language[this.gameName].placeholder' v-model="teamName" />
        </div>
        <div class="row">
          <input class="input" placeholder="Work Email" v-model="email" />
        </div>
        <div class="field field--button">
          <button class="button" type="submit" :style="{ 'background-color': $parent.buttonColor }"
            @click="SendTeamName()">{{ $parent.language[this.gameName].go }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import '../assets/form.css';
</style>

<script>
import axios from 'axios'
export default {
  name: 'StartGame',
  props: {
    msg: String,
    dbid: String,
    gameName: String
  },
  data() {
    return {
      teamName: "",
      boxid: "",
      nameError: false,
      clicked: false
    }
  },
  methods: {
    async SendTeamName() {
      if (this.teamName == "") {
        this.nameError = true;
        this.clicked = false;
      } else {
        this.nameError = false;
        this.clicked = true;

        await axios.post(process.env.VUE_APP_ip + '/newgame', { "name": this.teamName, "dbid": this.dbid, 'chosenLanguage': this.$parent.chosenLanguage })
        this.$emit('updatestage', "")
      }

    }
  }
}
</script>


